@import '../../../../constants/variables.scss';

.item {
  margin: 0 0 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  @include flex-container(row, center, flex-start, noWrap);
  padding: 0;
  background-color: var(--sgColorsWhite);
  color: var(--textPrimary);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  gap: 12px;
}

.icon {
  width: 18px;
  height: 18px;
}
