@import '../../../constants/variables.scss';

.commonButton {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 8px;
  @include flex-container(row, center, flex-start, nowrap);
}

.ghosted {
  composes: commonButton;

  color: var(--primaryLow);
  background-color: var(--primaryWhite);

  &:hover {
    background-color: var(--greyLight);
  }
}

.primary {
  composes: commonButton;

  color: var(--primaryWhite);
  background-color: var(--buttonBright);

  &:hover {
    background-color: var(--primary);
  }

  &:active {
    color: var(--primaryLight);
    background-color: var(--primary);
  }

  &:disabled {
    background-color: var(--primaryLight);
  }
}

.secondary {
  composes: commonButton;

  color: var(--primaryBright);
  background-color: transparent;

  &:hover {
    background-color: var(--primaryWhite);
  }

  &:active {
    background-color: var(--greyDivider);
  }
}

.activeButton {
  composes: commonButton;
  background-color: var(--primaryLight);
  color: var(--textPrimary);

  &:hover {
    background-color: var(--primaryLight);
  }
}

.icon {
  margin-right: 12px;

  path {
    fill: var(--primaryBright);
  }
}

.activeIcon {
  path {
    fill: var(--primaryBright);
  }
}
