@import '../../../constants/variables.scss';

.triggerContainer {
  display: inline-block;
}

.dropdown {
  border-radius: 3px;
  z-index: $dropdownZIndex;
  box-shadow: 0 3px 8px hsla(var(--sgColorsActionH), var(--sgColorsActionS), calc(var(--sgColorsActionL) * 0.8), 0.15);
  color: var(--sgColorsShade001);
  min-width: 140px;
  background-color: var(--sgColorsWhite);
}
