$tabletPoint: 1024px;
$largePoint: 1800px;

$baseTransition: 0.5s;
$baseRadius: 16px;

$modalZIndex: 10;
$dropdownZIndex: $modalZIndex + 1;

@mixin flex-container($direction, $align, $justify, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}
