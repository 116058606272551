@import '../../constants/variables.scss';

.navbarContainer {
  @include flex-container(row, center, space-between, noWrap);
  padding: 20px 0;
  position: sticky;
  top: 0;
  background-color: var(--backPrimary);
  z-index: 5;

  &_search {
    margin-right: 20px;
    min-width: 260px;
  }

  &_row {
    @include flex-container(row, center, flex-start);
  }
}

.switchLabel {
  cursor: pointer;
}

.row {
  gap: 42px;
}
